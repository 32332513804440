import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import Layout from '@/components/Layout/Layout'
import Container from '@/components/Layout/Container'
import ContainerSidebar from '@/components/Layout/ContainerSidebar'
import Wysiwyg from '@/components/Wysiwyg'
import TextAndImage from '@/components/TextAndImage'
import { getImage } from 'gatsby-plugin-image'
import FileRepeater from '@/components/FileRepeater'
import MainHeading from '@/components/MainHeading'
import Seo from '@/components/Seo'
import LoadMore from '@/components/LoadMore'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'
import { seotype } from '@/types.d'
interface BooksData {
  data: {
    form: any
    page: {
      title: string
      gwContentCol1: any
      gwContentCol2: any
      gwContentCol3: any
      featuredImage: any
      uri: string
      seo: seotype
      language: {
        slug: string
      }
      translations: []
    }
    allWpGwBook: any
    mainMenu: any
    langData: any
    wp: any
  }
}

const BooksTemplate = ({ data }: BooksData) => {
  const { t, i18n } = useTranslation()

  useEffect(() => {
    i18n.changeLanguage(page.language.slug)
  }, [])

  const { page } = data
  const { wp } = data
  const { form } = data
  const { allWpGwBook } = data
  const { mainMenu } = data

  const latestBook: any = allWpGwBook.nodes[0]
  const latestBookImg = getImage(latestBook?.featuredImage?.node?.localFile)
  const langData = { languages: wp.languages, currentLang: page.language.slug, translations: page.translations }
  const files =
    langData.currentLang == 'fi'
      ? { subTitle: wp.files.subTitleFi, heading: wp.files.headingFi, files: wp.files.filesFi }
      : langData.currentLang == 'en'
      ? { subTitle: wp.files.subTitleEn, heading: wp.files.headingEn, files: wp.files.filesEn }
      : langData.currentLang == 'sv'
      ? { subTitle: wp.files.subTitleSv, heading: wp.files.headingSv, files: wp.files.filesSv }
      : { subTitle: wp.files.subTitleFi, heading: wp.files.headingFi, files: wp.files.filesFi }

  return (
    <Layout featuredImage={page.featuredImage} lang={langData} nav={mainMenu} form={form}>
      {page.seo && (
        <Seo
          title={page?.seo?.title}
          description={page?.seo?.metaDesc}
          keywords={page?.seo?.metaKeyword}
          pathname={page?.uri}
          lang={page?.language?.slug}
          image={page?.featuredImage?.node?.localFile?.url}
          twitterTitle={page?.seo?.twitterTitle}
          twitterDescription={page?.seo?.twitterDescription}
          opengraphTitle={page?.seo?.opengraphTitle}
          opengraphDescription={page?.seo?.opengraphDescription}
          opengraphImage={page?.seo?.opengraphImage?.localFile?.url}
          twitterImage={page?.seo?.twitterImage?.localFile?.url}
        />
      )}
      <Container>
        <MainHeading>{page.title}</MainHeading>
        <Wysiwyg className="max-w-screen-md w-full my-10">{page.gwContentCol1}</Wysiwyg>
        {latestBook && (
          <TextAndImage
            heading={latestBook.title}
            link={latestBook.uri}
            isIndent={true}
            linkText={t('Lue lisää')}
            contentUpper={latestBook.gwBooksShortSummary}
            img={latestBookImg}
            imgBg={'bg-green'}
            className="mt-12 md:mt-20 mb-20 md:mb-20"
          >
            <p>{latestBook.gwBooksLongSummary}</p>
          </TextAndImage>
        )}
      </Container>
      <ContainerSidebar
        sidebar={<FileRepeater files={files.files} heading={files.heading} subHeading={files.subTitle} />}
        className="mt-10 mb-20"
      >
        <Wysiwyg>{page.gwContentCol2}</Wysiwyg>
      </ContainerSidebar>
      <Container>
        <Wysiwyg className="max-w-screen-md w-full mt-10 mb-20">{page.gwContentCol3}</Wysiwyg>
      </Container>
      <Container className="lg:hidden mb-20">
        <FileRepeater files={files.files} heading={files.heading} subHeading={files.subTitle} />
      </Container>
      {allWpGwBook.nodes && (
        <Container className="bg-lightlatte py-10 -mb-14">
          <h2 className="mb-10 text-3xl">
            <Trans>Kalevalaseuran vuosikirjat vuodesta 1980</Trans>
          </h2>
          <LoadMore
            buttonText={t('Näytä lisää')}
            classNameButton="flex items-center justify-center mt-10"
            postType="books"
            total={allWpGwBook.totalCount}
            className="grid sm:grid-cols-2 gap-10 sm:gap-6 md:grid-cols-4 md:gap-6 xl:gap-10"
            posts={allWpGwBook.nodes}
          />
        </Container>
      )}
    </Layout>
  )
}

export const BooksPageQuery = graphql`
  query BooksPageById(
    $id: String
    $postlang: [String]
    $menu: [WpMenuLocationEnum]
    $translang: String
    $formNum: Int
  ) {
    # selecting the current page by id
    page: wpPage(id: { eq: $id }) {
      id
      title
      gwContentCol1
      gwContentCol2
      gwContentCol3
      ...seo
      ...featuredHeroImage
      ...langdata
    }
    allWpGwBook(sort: { fields: gwBooksYear, order: DESC }, filter: { language: { slug: { in: $postlang } } }) {
      ...allBooks
    }
    wp {
      ...bookFiles
      ...allLang
    }
    mainMenu: wpMenu(locations: { in: $menu }) {
      ...navigation
    }
    locales: allLocale(filter: { language: { eq: $translang } }) {
      ...stringTranslations
    }
    form: wpGravityFormsForm(formId: { eq: $formNum }) {
      ...GravityFormFields
    }
  }
`

export default BooksTemplate
